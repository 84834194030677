<template>
  <b-card-code title="All Courses " no-body>
    <b-card-body>
      <div class="d-flex justify-content-between flex-wrap">
        <!-- sorting  -->

        <!-- filter -->
        <b-form-group label="Filter" label-cols-sm="2" label-align-sm="left" label-size="sm" label-for="filterInput"
          class="mb-0">
          <b-input-group size="sm">
            <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>

        <b-row class="match-height mt-50"> </b-row>
      </div>
      <b-table v-if="courses.length > 0" striped hover responsive class="position-relative" :per-page="perPage"
        :items="courses" :fields="fields">
        <template #cell(imageCover)="data">
          <b-avatar :src="data.item.imageCover" />
        </template>
        <template #cell(Action)="data">
          <span>
            <span>
              <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                <template v-slot:button-content>
                  <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                </template>
                <b-dropdown-item :to="{ path: `/courses/${data.item._id}` }">
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span>Edit</span>
                </b-dropdown-item>
                <!-- <editStudent :course1="data.item" /> -->
                <duplicateCourse :item="data.item" />
                <deleteCourse :id="data.item._id" />
              </b-dropdown>
            </span>
          </span>
        </template>
      </b-table>
    </b-card-body>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <div>
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="prams.limit" first-number last-number
          prev-class="prev-item" next-class="next-item" class="mb-0">
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-card-body>

    <template #code>
      <!-- {{ codeKitchenSink }} -->
    </template>
  </b-card-code>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
} from "bootstrap-vue";
// import { codeKitchenSink } from "./code";
import coursesApi from "@/apiServices/coursesApi";
import deleteCourse from "@/views/courses pages/delete course.vue";
import duplicateCourse from "@/views/courses pages/duplicate.vue";
import editStudent from "@/views/courses pages/components/editstudents.vue";

export default {
  components: {
    BCardCode,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup, editStudent,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    deleteCourse,
    duplicateCourse,
  },
  data() {
    return {
      prams: {
        sort: "-createdAt",
        page: 1,
        limit: 5,
      },
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "imageCover",
          label: "imageCover",
        },
        { key: "title", label: "Title" },
        { key: "category.name", label: "category" },
        { key: "sections.length", label: "sections" },
        { key: "students.length", label: "students" },
        "Action",
      ],
      /* eslint-disable global-require */
      courses: [],
      /* eslint-disable global-require */
      status: [],
      //   codeKitchenSink,
    };
  },
  created() {
    this.getCourses();
  },
  computed: {},
  mounted() {
    // this.totalRows = this.items.length;
  },
  watch: {
    currentPage() {
      this.prams.page = this.currentPage;
      this.getCourses();
    },
    filter() {
      this.prams.page = this.currentPage;
      this.prams.title = this.filter;
      this.getCourses();
    },
  },
  methods: {
    deletecourseFromlist(id) {
      console.log("hi from parent " + id + "******" + this.courses.length);
      this.courses = this.courses.filter(function (obj) {
        return obj._id != id;
      });
    },
    getCourses() {
      if (this.filter == "") {
        delete this.prams.title;
      }
      coursesApi.getCourses(this.prams).then((response) => {
        this.courses = [];
        if (response.data) {
          this.courses = response.data;
          this.currentPage = response.paginationResult?.currentPage;
          this.totalRows =
            response.paginationResult?.numberOfPages * this.prams.limit;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "get All Courses",
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      //   this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
