<template>
  <div>
    <!-- modal trigger button -->

    <b-row>
      <b-col>
        <b-dropdown-item @click="showModal">
          <feather-icon icon="Edit2Icon" class="mr-50" />
          <span>edit students</span>
        </b-dropdown-item>
      </b-col>
    </b-row>

    <b-modal size="xl" scrollable ref="my-modal-edit-edit1" modal-class="modal-info" hide-footer title="update course">
      <b-overlay :show="show" rounded="sm">

        <form-wizard v-if="course != null" color="#00cfe8" :title="null" :subtitle="null" shape="circle"
          finish-button-text="update course" back-button-text="Previous" class="mb-3" @on-complete="infCourseValidation">

          <tab-content title="Students">
            <validation-observer ref="StudentsRules" tag="form">
              <b-row>
                <b-col cols="12" class="mb-2">
                  <h5 class="mb-0">Course Content</h5>
                  <small class="text-muted">Enter Course Sections .</small>
                </b-col>
              </b-row>

              <b-row>
                <b-card v-if="course._id" class="w-100">
                  <b-row>
                    <userSidebar @addusers="addusers" />
                    <b-col md="6"></b-col>

                  </b-row>
                </b-card>
              </b-row>

              <b-row v-if="course._id" v-for="(user, index) in course.students" :id="user.id" :key="user.id"
                class="w-100">
                <b-card class="w-100" v-if="user.student">
                  <b-row>
                    <b-col md="3">
                      <b-form-checkbox :value="user.student">
                        <b-avatar :src="user.student.profileImg" />
                        {{ user.student.name }}
                      </b-form-checkbox>
                    </b-col>

                    <b-col md="3">
                      <b-form-group label="start Date" label-for="start Date">
                        <validation-provider #default="{ errors }" name="start Date"
                          :vid="'register-startDate' + user.student._id" rules="required">
                          <b-form-datepicker :id="'register-startDate' + user.student._id" v-model="user.startDate"
                            name="register-expairation Date"
                            :state="errors.length > 0 ? false : null"></b-form-datepicker>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <b-form-group label="expairation Date" label-for="expairation Date">
                        <validation-provider #default="{ errors }" name="expairation Date"
                          :vid="'register-expairationDate' + user.student._id" rules="required">
                          <b-form-datepicker :id="'register-expairationDate' + user.student._id"
                            v-model="user.expairationDate" name="register-expairation Date"
                            :state="errors.length > 0 ? false : null"></b-form-datepicker>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="danger" class="btn-icon"
                        @click="deleteStudentFromCourse(user, index)">
                        <feather-icon icon="Trash2Icon" />
                        <span class="mr-50">remove </span>
                      </b-button>
                    </b-col>
                  </b-row>

                  <!-- <userCard :user="user"> </userCard> -->
                </b-card>
              </b-row>
            </validation-observer>
          </tab-content>
        </form-wizard>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
  BListGroup,
  BListGroupItem,
  BRow,
  BCol,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import coursesApi from "@/apiServices/coursesApi";
import { required, email, max_value, numeric } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import imagesApi from "@/apiServices/imagesApi";
import userSidebar from "@/views/users pages/components/usersSidebar.vue";
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import useriesApi from "@/apiServices/userApi";

export default {
  props: ["course1"],

  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
    FormWizard,
    TabContent,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    userSidebar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show: false,
      required,
      email,
      max_value,
      numeric,
      course: null,
      options: ["percentage", "price"],

      prams: {
        id: "",
      },
    };
  },
  watch: {

  },
  created() {
    /* this.course = {
       students: this.course1.students,
       id: this.course1.id,
       _id: this.course1._id
     }
 */
    // this.course.imageCover = null;
  },
  methods: {

    showModal() {
      this.getCourse();

      this.$refs["my-modal-edit-edit1"].show();
    },
    hideModal() {
      this.$refs["my-modal-edit-edit1"].hide();
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden

      this.updatecourse();
      this.$refs["my-modal-edit-edit1"].toggle("#toggle-btn");
    },
    addusers(students) {
      console.log("this is fron edit" + students);
      students.forEach((element1) => {
        if (
          this.course.students.findIndex(
            (element) => element?.student?._id === element1._id
          ) === -1
        ) {
          this.course.students.push({
            student: element1,
            expairationDate: "",
          });
        }
      });
    },

    deleteStudentFromCourse(user, index) {
      this.course.students.splice(index, 1);
      user.student.courses = user.student.courses.filter(
        (item) => item.course !== this.course._id
      );
      this.studentUpdate(user.student);
    },
    studentUpdate(user) {
      useriesApi.updateuser(user).then((response) => {
        if (response.data) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Students list Updated",
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },
    addCouseToStudent(user) {
      this.show = true;

      if (
        user?.courses?.findIndex(
          (element) => element.course === this.course._id
        ) === -1
      ) {
        user.courses.push({ course: this.course._id, sectionOrder: 0 });
      }

      this.studentUpdate(user);
    },
    myLoop() {
      //  create a loop function
    },
    getCourse() {
      coursesApi.getCourse(this.course1._id).then((response) => {
        if (response.data) {
          console.log(response.data);
          this.course = response.data?.data;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.course.title,
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },
    async updateCoures(courseFormData) {
      this.show = true;
      await coursesApi.updatecourseStudents(courseFormData).then((response) => {
        if (response.data) {
          this.course = response.data?.data;

          this.show = false;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "course updated complited",
              icon: "EditIcon",
              variant: "success",
            },
          });


        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
          this.show = false;

        }
      });
    },
    infCourseValidation() {
      this.show = true;

      return new Promise((resolve, reject) => {
        this.$refs.StudentsRules.validate().then((success) => {
          if (success) {

            this.course.students?.forEach((element) => {
              if (element && element.student) {
                element.student = element?.student?._id;
              }
            });

            this.updateCoures({
              students: this.course.students,

            });
            resolve(true);




          } else {
            reject();
          }
        });
      });
    },
  },



};
</script>
